
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { ElTable } from "element-plus";
import moment from "moment";
import JwtService from "@/core/services/JwtService";
import { Search } from '@element-plus/icons-vue'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TransportNoteDetailsModal from "@/components/modals/forms/TransportNoteDetailsModal.vue";
import { Modal } from "bootstrap";


export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {
    TransportNoteDetailsModal
  },
  methods:{
    getProductTableData(data){
      console.log('productData', data)
      let productInfo = data?.productInfo;
      for(let item of data?.productData){
        let newObj = {};
        let tableDataObj = {};
        newObj['product_category'] = productInfo?.product_category,
        newObj['product_description'] = productInfo?.product_description,
        newObj['product_manufacturer'] = productInfo?.product_manufacturer,
        newObj['warehouse'] = productInfo?.consignee_warehouse,
        newObj['uom'] = productInfo?.uom
        newObj['remark'] = data.remark
        tableDataObj = Object.assign(newObj, item)
        this.tableData.push(tableDataObj)

      }
      let productDetails = data?.productData.map(({id, ...obj}) => {
         return {...obj, material_condition_id: obj.material_condition_id?.category_id}
      })

      this.grnDetails.push({
        do_detail_id : productInfo?.do_detail_id,
        product_details: productDetails
      })
    },
    getTransportInfo(data){
      console.log('modal data', data)
      this.formData.transport = data?.tn_no;
      this.formData.tn_id = data?.tn_id;
    }
  },


  setup(props, { emit }) {
    const store = useStore();
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);
    const uom_data = ref([]);
    const tableData = ref<Array<any>>([]);
    const grnDetails = ref<Array<any>>([]);
    const tnListData = ref<Array<TransportList>>([]);
    const inward_type_data = ref([]);
    const supplier_data = ref([]);
    const branch_data = ref([]);
    const consignee_branch_data = ref([]);
    const consignee_data = ref([]);
    const divert_data = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<any[]>([]);

    const multipleTableTnRef = ref<InstanceType<typeof ElTable>>();
    const multipleTnSelection = ref<TransportList[]>([]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();
    const dialogSelectProductTableVisible = ref(false);
    const addPlanningIndentRef = ref<null | HTMLElement>(null);

    getInwardTypes();
    getTnList();

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Goods Receipt Note", []);
    });

    interface TransportList {
      stock_transport_note_id: string;
      stock_transport_note_no: string;
      vehicle_no: string;
      lr_no: string;
      lr_date: string;
      driver_name: string;
      driver_mobile: string;
    }

    const formData = ref({
      supplier_select: "",
      supplier_branch_select: "",
      transport: "",
      tn_id: '',
      inward_type_select: 1,
      divert_select: 0,
      invoice_date: "",
      invoice_no: "",
      dc_no: "",
      dc_date: "",
      grn_date: new Date(),
    });

    const rules = ref({
      supplier_select: [
        {
          required: true,
          message: "Supplier is required",
          trigger: "change",
        },
      ],
      supplier_branch_select: [
        {
          required: true,
          message: "Supplier Branch is required",
          trigger: "change",
        },
      ],
      transport: [
        {
          required: true,
          message: "Transport No is required",
          trigger: "change",
        },
      ],
      inward_type_select: [
        {
          required: true,
          message: "Inward Type is required",
          trigger: "change",
        },
      ],
      invoice_date: [
        {
          required: true,
          message: "Invoice Date is required",
          trigger: "change",
        },
      ],
      invoice_no: [
        {
          required: true,
          message: "Invoice No is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      let user = JSON.parse(JwtService.getToken());
      debugger;
      if (tableData.value?.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select At Least One PO.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

  let requestData = {
    user_id: user.user_id,
    grn_date : moment(data.grn_date).format("YYYY-MM-DD"),
    supplier_company_id : data.supplier_select,
    supplier_company_branch_id : data.supplier_branch_select,
    tn_id : formData.value.tn_id,
    grn_inword_type_id : data.inward_type_select,
    divert_yn : data.divert_select,
    invoice_no : data.invoice_no,
    invoice_date : moment(data.invoice_date).format("YYYY-MM-DD"),
    dc_no : data.dc_no,
    dc_date : moment(data.dc_date).format("YYYY-MM-DD"),
    grn_details : grnDetails.value
}
      console.log('db_data', requestData);
      try {
        await store.dispatch(ActionsFi.CUST_CREATE_GRN, requestData)
          .then(({ data, is_error, status, message }) => {
            loading.value = false;
            if (data?.grn_no && status !== 500){
            setTimeout(() => {
              Swal.fire({
                html: ""+
                      data?.grn_no+
                      "<br>Goods receipt note created successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                router.push({ name: "apps-goods-reciept-note-listing" });
              });
            }, 2000);
            }else if(data == "" && is_error == false && status == 500){
              Swal.fire({
                  text: message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "apps-goods-reciept-note-listing" });
                });
          }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getWarehouseList() {
      let values = {
        company_id: formData.value["supplier_select"],
      };
      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          branch_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }

      // let values = { trade_type: 1 };
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }


    async function getInwardTypes() {
      await store
        .dispatch(ActionsFi.CUST_GET_INWARD_TYPE_LIST)
        .then(({ data }) => {
          inward_type_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getTnList() {
      await store
        .dispatch(ActionsFi.CUST_GET_TRANSPORT_NO_LIST)
        .then(({ data }) => {
          tnListData.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    const handleSelectionChange = (val: []) => {
      multipleSelection.value = val;
    };

    const handleSelectionTnChange = (val: TransportList[]) => {
      debugger;

      let index = val.length != 0 ? val.length - 1 : 0;
      var rowValue = val[index];
      multipleTnSelection.value = [];
      multipleTnSelection.value.push(rowValue);
      val.forEach((row) => {
        if (row === rowValue) {
          multipleTableTnRef.value!.toggleRowSelection(row, true);
        } else {
          multipleTableTnRef.value!.toggleRowSelection(row, false);
        }
      });
    };
    const onContinueTn = () => {
      debugger;
      if (multipleTnSelection.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one Transport",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        formData.value["transport"] =
          multipleTnSelection.value[0].stock_transport_note_no;
        dialogSelectProductTableVisible.value = false;
      }
    };

    async function getBranchList() {
      let values = {
        company_id: formData.value["supplier_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          consignee_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const transport_data = ref();
    async function getTransportList() {
      alert('call')
      let values = {
        client_company_id : 1,//formData.value["supplier_select"],
        client_company_branch_id : 1//formData.value["supplier_branch_select"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_TN_PENDINT_LIST, values)
        .then(({ data }) => {
          debugger;
          transport_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    
    const openTnDetailsModal = () =>{
      const modal = new Modal(document.getElementById("kt_modal_transport_note"));
      modal.show();
    }
    

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      getInwardTypes,
      getCompanyList,
      supplier_data,
      addPlanningIndentRef,
      consignee_data,
      formRef,
      tableData,
      loading,
      dialogSelectProductTableVisible,
      addCompanyModalRef,
      uom_data,
      tnListData,
      inward_type_data,
      multipleTableRef,
      handleSelectionChange,
      multipleSelection,
      multipleTableTnRef,
      divert_data,
      branch_data,
      multipleTnSelection,
      handleSelectionTnChange,
      getTnList,
      onContinueTn,
      getWarehouseList,
      router,
      getBranchList,
      consignee_branch_data,
      getTransportList,
      transport_data,
      openTnDetailsModal,
      grnDetails
    };
  },
});
