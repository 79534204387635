
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import AddProductForGrnModal from "./AddProductForGrnModal.vue";

export default defineComponent({
  name: "transport-note-product-details-modal",
  directives: { mask },
  props: {
    data: {
      type: Object,
    },
    getProductTableData: {
      type: Function,
    },
  },
  components: {
    AddProductForGrnModal,
  },
  methods: {},

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const TransportNoteProductDetailsModal = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const tnProductsData = ref([]);
    const product = ref("");

    async function getTNProductList() {
      let values = { tn_id: props?.data?.tnId };
      await store
        .dispatch(ActionsFi.CUST_GET_TN_PRODUCTS, values)
        .then(({ data }) => {
          debugger;
          tnProductsData.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    onMounted(async () => {
      TransportNoteProductDetailsModal.value?.addEventListener(
        "show.bs.modal",
        async function (event) {
          tnProductsData.value = [];
          product.value = "";
          await getTNProductList();
        }
      );
    });

    const setProduct = (row) => {
      product.value = row;
      console.log("selcted product", product.value);
    };

    return {
      loading,
      TransportNoteProductDetailsModal,
      tnProductsData,
      setProduct,
      product,
    };
  },
});
