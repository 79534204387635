
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "transport-note-add-product-modal",
  directives: { mask },
  props: {
    data: {
      type: Object,
    },
    getProductTableData: {
      type: Function,
    },
  },
  components: {},
  methods: {},

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const TransportNoteAddProductForGrnModal = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const product = ref("");
    const productMaterialConditionList = ref([]);
    const productData: any = ref([]);
    const rowId = ref(1);

    const formData = ref({
      remark: "",
    });

    const getProductMaterialCondition = async () => {
      let params = { pq_detail_id: props.data?.pq_detail_id };
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_MATERIAL_CONDITION, params)
        .then(({ data }) => {
          if (data?.length) {
            productMaterialConditionList.value = data;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    onMounted(async () => {
      TransportNoteAddProductForGrnModal.value?.addEventListener(
        "show.bs.modal",
        async function (event) {
          console.log("th is last modal", props?.data?.product);
          productData.value = [
            {
              id: 1,
              company_batch_no: "",
              received_quantity: "",
              // gross_quantity: "",
              no_of_pieces: "",
              material_condition_id: "",
              hsn_code: "",
            },
          ];
          productMaterialConditionList.value = [];
          await getProductMaterialCondition();
        }
      );
    });

    const setProduct = (row) => {
      product.value = row;
    };

    const addMoreRow = () => {
      rowId.value++;
      productData.value.push({
        id: rowId.value,
        company_batch_no: "",
        received_quantity: "",
        // gross_quantity: "",
        no_of_pieces: "",
        material_condition_id: "",
        hsn_code: "",
      });
    };

    const deleteRow = (row) => {
      if (row.id == 1) {
        return;
      }
      var index = productData.value
        .map((deteRow) => {
          return deteRow.id;
        })
        .indexOf(row?.id);
      productData.value.splice(index, 1);
      if (productData.value.length === 1) {
        rowId.value = 1;
      }
    };

    const sendData = () => {
      let isRowwithEmtpyFiled = false;
      for (let row of productData.value) {
        for (let field in row) {
          if (!row[field] && !['company_batch_no','no_of_pieces'].includes(field)) {
            isRowwithEmtpyFiled = true;
            break;
          }
        }
      }

      if (isRowwithEmtpyFiled) {
        Swal.fire({
          text: "Please enter 'received Qty', 'Material Condition' and 'HSN'. these fields are mandatory.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      hideModal(TransportNoteAddProductForGrnModal.value);

      let data = {
        productInfo: props?.data?.product,
        productData: productData.value,
        remark: formData.value.remark,
      };
      props.getProductTableData?.(data);
    };

    const validateInput = (editedRow, fieldName) => {
      if (!/^\d*\.?\d*$/.test(editedRow[fieldName]) || editedRow[fieldName] == 0){
        editedRow[fieldName] = editedRow[fieldName].slice(0, -1);
        return
      }
    }

    return {
      loading,
      TransportNoteAddProductForGrnModal,
      productData,
      formData,
      productMaterialConditionList,
      setProduct,
      addMoreRow,
      deleteRow,
      sendData,
      validateInput
    };
  },
});
