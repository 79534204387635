
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import TnProductDetails from "./TnProductDetailsModal.vue";

export default defineComponent({
  name: "transport-note-details-modal",
  directives: { mask },
  props: {
    data: {
      type: Object,
    },
    getProductTableData: {
      type: Function,
    },
    getTransportInfo: {
      type: Function,
    },
  },
  components: {
    TnProductDetails,
  },
  methods: {},

  setup(props) {
    const store = useStore();
    const TransportNoteDetailsModal = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const transportNoteData = ref([]);
    const tnId = ref("");

    async function getTransportNoteList() {
      let values = {
        client_company_id: props?.data?.ClientCompanyId,
        client_company_branch_id: props?.data?.clientCompanyBranchId,
      };
      await store
        .dispatch(ActionsFi.CUST_GET_TN_PENDINT_LIST, values)
        .then(({ data }) => {
          transportNoteData.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    onMounted(async () => {
      TransportNoteDetailsModal.value?.addEventListener(
        "show.bs.modal",
        async function (event) {
          transportNoteData.value = [];
          tnId.value = '';
          await getTransportNoteList();
        }
      );
    });

    const setTranporstNote = (row) => {
      tnId.value = row?.tn_id;
      props.getTransportInfo?.(row);
    };

    return {
      loading,
      TransportNoteDetailsModal,
      transportNoteData,
      tnId,
      setTranporstNote,
    };
  },
});
